import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 2,
      width: '100%',
      background: '#c4c4c4',
    },
  },
  tab: {
    padding: theme.spacing(2),
    width: '50%',
  },
}));

export default ({ children }) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { t } = useTranslation('labels');
  const cls = useStyle();

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(e, num) => setTabIndex(num)}
        className={cls.root}
        variant="scrollable"
      >
        <Tab
          value={0}
          label={t('existingCustomer')}
          className={cls.tab}
        />
        <Tab
          value={1}
          label={t('newCustomer')}
          className={cls.tab}
        />
      </Tabs>
      <Box py={3} px={2}>
        {children(tabIndex)}
      </Box>
    </>
  );
};
