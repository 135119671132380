import React from 'react';
import { useLoading } from 'q3-ui-rest';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Box from '@material-ui/core/Box';
import {
  HeaderWithForm,
  FeatureListWithIcons,
  ContentSection,
  ContentSectionHeader,
} from 'q3-blocks';

export const Services = ({ description }) => {
  const {
    services: { nodes = [] },
  } = useStaticQuery(graphql`
    {
      services: allContentfulService {
        nodes {
          contentful_id
          title
          excerpt {
            excerpt
          }
          icon {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  const features = nodes.map((node) => ({
    title: node.title,
    description: node.excerpt.excerpt,
    icon: () => (
      <GatsbyImage
        {...node.icon}
        alt={node.title}
        style={{
          width: 22,
          height: 22,
          filter: 'saturate(0.75)',
        }}
      />
    ),
  }));

  return (
    <Box my={4}>
      <ContentSection maxWidth="lg">
        <ContentSectionHeader
          title="The Gentek Difference"
          subtitle={description}
        />
        <FeatureListWithIcons features={features} />
      </ContentSection>
    </Box>
  );
};

const Difference = ({ children }) => {
  useLoading();

  return (
    <HeaderWithForm
      title="Hello"
      description="HELLO"
      backgroundColor="rgba(2,2,2,0.85)"
      imageProps={{
        fluid: {
          src:
            'https://source.unsplash.com/collection/219941',
        },
      }}
    >
      {children}
    </HeaderWithForm>
  );
};

Difference.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Difference;
