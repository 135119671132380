import React from 'react';
import Image from 'gatsby-image';
import {
  Box,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import RichText from 'gatsby-theme-q3/src/components/RichText';

const useStyle = makeStyles((theme) => ({
  image: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overlay: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.34,
  },
  section: ({ gutters = false }) => ({
    position: 'relative',
    padding: `${theme.spacing(6)} 0 ${theme.spacing(
      gutters ? 40 : 6,
    )} 0`,
  }),
  content: {
    position: 'relative',

    '& *': {
      color: 'inherit',
    },

    '& h2': {
      marginBottom: theme.spacing(1),
    },

    '& p': {
      maxWidth: 320,
    },
  },
}));

const CallToActionBlock = ({
  descriptionRt,
  image,
  title,
  ...rest
}) => {
  const cls = useStyle(rest);

  return (
    <Box
      className={cls.section}
      color="primary.contrastText"
      component="section"
    >
      {image && (
        <Image
          className={cls.image}
          {...image}
          aria-hidden
        />
      )}
      <Box className={cls.overlay} />
      <Container className={cls.content} maxWidth="md">
        <Typography
          color="inherit"
          variant="h1"
          component="h2"
        >
          {title}
        </Typography>
        {descriptionRt && (
          <RichText json={JSON.parse(descriptionRt.raw)} />
        )}
      </Container>
    </Box>
  );
};

export default CallToActionBlock;
