import React from 'react';
import Image from 'gatsby-image';
import { get, size, map } from 'lodash';
import { Box, Grid, Typography } from '@material-ui/core';

// eslint-disable-next-line
const PageEmployees = ({ small, photos }) => {
  if (!size(photos)) return null;

  return (
    <Grid container spacing={1}>
      {map(photos, (photo) => {
        const title = get(photo, 'title');

        return (
          <Grid
            className="employee"
            key={title}
            item
            xs={6}
            md={small ? 3 : 4}
          >
            <Box style={{ width: '100%' }}>
              <Image
                {...get(photo, 'headshot')}
                style={{
                  height: '16rem',
                  width: '100%',
                }}
              />
              <Box p={1}>
                <Typography variant="h6" component="p">
                  {title}
                </Typography>
                <Typography
                  style={{ fontSize: '0.833rem' }}
                  component="small"
                >{`${get(photo, 'tag')} since ${get(
                  photo,
                  'since',
                )}`}</Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PageEmployees;
