import React from 'react';
import {
  Container,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { chunk, flatten, size } from 'lodash';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import CallToActionBlock from '../components/CallToActionBlock';
import EmployeePhotos from '../components/EmployeePhotos';

const useStyle = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontSize: '3.047rem',

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  columns: {
    columnCount: 2,
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    },
  },
  header: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  contrast: {
    '& *': {
      color: theme.palette.primary.contrastText,
    },
  },
  contrastHalf: {
    [theme.breakpoints.up('md')]: {
      '& .employee:nth-child(n+4) *': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  overlap: {
    [theme.breakpoints.up('md')]: {
      marginTop: `-${theme.spacing(28)}`,
      paddingTop: `${theme.spacing(24)}`,
    },
  },
}));

const AboutUs = ({
  banner,
  body,
  callsToAction,
  employees,
}) => {
  const cls = useStyle();
  const [top, middle, bottom] = callsToAction;
  const team = chunk(employees, 8);
  const employeePhotos = flatten(team);

  return (
    <Box component="main" bgcolor="background.paper">
      <Container
        className={cls.header}
        component="section"
        maxWidth="md"
      >
        <Typography className={cls.title} variant="h1">
          {banner.title}
        </Typography>
        {body && (
          <Box className={cls.columns}>
            <RichText json={JSON.parse(body.raw)} />
          </Box>
        )}
      </Container>
      {top && <CallToActionBlock {...top} />}
      {middle && <CallToActionBlock {...middle} />}
      {size(employeePhotos) > 0 && (
        <Container component="section" maxWidth="md">
          <Box py={4}>
            <EmployeePhotos small photos={employeePhotos} />
          </Box>
        </Container>
      )}
      {bottom && <CallToActionBlock gutters {...bottom} />}
    </Box>
  );
};

AboutUs.defaultProps = {
  banner: {
    title: 'About us',
  },
  body: null,
  callsToAction: [],
  leadershipInfo: null,
  founders: [],
  employees: [],
  teamInfo: null,
};

AboutUs.propTypes = {
  banner: PropTypes.shape({
    title: PropTypes.string,
  }),
  body: PropTypes.shape({
    raw: PropTypes.string,
  }),
  callsToAction: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      // eslint-disable-next-line
      image: PropTypes.object,
    }),
  ),
  leadershipInfo: PropTypes.shape({
    raw: PropTypes.string,
  }),
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      // eslint-disable-next-line
      headshot: PropTypes.object,
    }),
  ),
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      // eslint-disable-next-line
      headshot: PropTypes.object,
    }),
  ),
  teamInfo: PropTypes.shape({
    raw: PropTypes.string,
  }),
};

export default AboutUs;
