import React from 'react';
import { get } from 'lodash';
import { Form, Field } from 'q3-ui-forms/src/builders';
import { navigate } from '@reach/router';
import { asOptions } from 'q3-ui-forms/src/helpers';
import useRest from 'q3-ui-rest';
import { useTranslation } from 'q3-ui-locale';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { HeaderWithForm } from 'q3-blocks';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import RichText from 'gatsby-theme-q3/src/components/RichText';
import { Services } from './difference';
import ResellerSignupTabs from '../components/ResellerSignupTabs';
import SEOHelmet from '../components/SEOHelmet';

const {
  OUTREACH: {
    COLLECTION_NAME,
    ENDPOINT,
    RESOURCE_NAME,
    RESOURCE_NAME_SINGULAR,
  },
} = COLLECTIONS;

const CURRENCIES = ['CAD', 'USD'];
const LANGUAGES = ['en-CA', 'fr-CA'];

const useStyle = makeStyles((theme) => ({
  root: {
    '& *': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const ExistingCustomerRegistrationForm = () => {
  const { t } = useTranslation('descriptions');
  const { post } = useRest({
    runOnInit: false,
    url: '/signup',
    key: 'user',
  });

  return (
    <Form
      submitLabel="signUp"
      initialValues={{
        company: '',
        firstName: '',
        lastName: '',
        email: '',
        kind: '',
        lang: 'en-CA',
        currency: 'CAD',
        subscribeToMailchimp: false,
      }}
      onSubmit={(values, actions) =>
        post(values, actions).then((data) => {
          navigate('/reseller-thanks');
          return data;
        })
      }
    >
      <Grid item xs={12}>
        <Typography gutterBottom>
          {t('signupExistingCustomers')}
        </Typography>
      </Grid>
      <Field name="firstName" type="text" required xl={6} />
      <Field name="lastName" type="text" required xl={6} />
      <Field name="email" type="text" required xl={6} />
      <Field
        name="companyCode"
        type="text"
        required
        xl={6}
      />
      <Field
        type="select"
        name="currency"
        options={asOptions(CURRENCIES)}
        label="Preferred currency"
        required
        xl={6}
        lg={6}
      />
      <Field
        type="select"
        name="lang"
        options={asOptions(LANGUAGES)}
        required
        xl={6}
        lg={6}
      />
      <Field
        type="checkbox"
        name="subscribeToMailchimp"
        xl={12}
        lg={12}
      />
    </Form>
  );
};

const NewCompanyRegistrationForm = () => {
  const { t } = useTranslation('descriptions');
  const { post } = useRest({
    url: ENDPOINT,
    collectionName: COLLECTION_NAME,
    pluralized: RESOURCE_NAME,
    key: RESOURCE_NAME_SINGULAR,
    runOnInit: false,
  });

  return (
    <Form
      onSubmit={(values, actions) =>
        post(values, actions).then((data) => {
          navigate('/reseller-thanks');
          return data;
        })
      }
      initialValues={{
        resourceOrigin: 'Signup',
        subject:
          'Registration request for Gentek reseller program',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        company: '',
        subscribeToMailchimp: false,
      }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom>
          {t('registerToday')}
        </Typography>
      </Grid>
      <Field
        name="company"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Field
        name="firstName"
        type="text"
        required
        xl={6}
        lg={6}
      />
      <Field
        name="lastName"
        type="text"
        required
        xl={6}
        lg={6}
      />
      <Field
        name="email"
        type="email"
        required
        xl={6}
        lg={6}
      />
      <Field name="tel" type="tel" required xl={6} lg={6} />
      <Field
        name="message"
        type="text"
        multiline
        rows={5}
        xl={12}
        lg={12}
      />
      <Field
        type="checkbox"
        name="subscribeToMailchimp"
        xl={12}
        lg={12}
      />
    </Form>
  );
};

export default ({ banner, description }) => {
  const cls = useStyle();
  return (
    <>
      <SEOHelmet
        title={get(banner, 'title')}
        description={get(description, 'description')}
      />
      <HeaderWithForm
        title={banner.title}
        description={
          banner?.leadIn?.raw ? (
            <Box className={cls.root} color="inherit">
              <RichText
                json={JSON.parse(banner.leadIn?.raw)}
              />
            </Box>
          ) : undefined
        }
        backgroundColor="rgba(2,2,2,0.85)"
        imageProps={banner.image}
      >
        <ResellerSignupTabs>
          {(tabIndex) => (
            <Box>
              {tabIndex === 0 && (
                <ExistingCustomerRegistrationForm />
              )}
              {tabIndex === 1 && (
                <NewCompanyRegistrationForm />
              )}
            </Box>
          )}
        </ResellerSignupTabs>
      </HeaderWithForm>
      <Services description={description?.description} />
    </>
  );
};
