import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import AboutUs from '../containers/AboutUs';
import ResellerSignup from '../containers/ResellerSignup';
import SEOHelmet from '../components/SEOHelmet';
import PageBody from '../components/PageBody';
import ProductsHeader from '../components/ProductsHeader';

// eslint-disable-next-line
const Page = ({ data: { page } }) => {
  const body = get(page, 'body.raw', '');

  const renderSeo = () => (
    <SEOHelmet
      title={
        get(page, 'seo.title') || get(page, 'banner.title')
      }
      description={get(
        page,
        'seo.metaDescription.metaDescription',
        '',
      )}
    />
  );

  // eslint-disable-next-line
  if (page.contentful_id === '6asyn4ChEJp6cJoQn8IoAL')
    return <ResellerSignup {...page} />;

  // eslint-disable-next-line
  if (page.contentful_id === '0zeLAwispR2BJP5HOelsi')
    return (
      <>
        {renderSeo()}
        <AboutUs {...page} />
      </>
    );

  return (
    <Box
      bgcolor="background.paper"
      position="relative"
      pb={2}
    >
      {renderSeo()}
      <ProductsHeader
        image={get(page, 'banner.image')}
        title={get(page, 'banner.title')}
      >
        <PageBody body={body} />
      </ProductsHeader>
    </Box>
  );
};

export default Page;

export const query = graphql`
  query getPageById($contentful_id: String) {
    page: contentfulPage(
      contentful_id: { eq: $contentful_id }
    ) {
      contentful_id
      banner {
        title
        leadIn {
          raw
        }
        image {
          alt: title
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid
          }
        }
      }
      body {
        raw
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
      }
      callsToAction {
        title
        descriptionRt {
          raw
        }
        image {
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
